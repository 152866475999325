<template>
  <div class="banners">
    <div class="content">
      <div class="nevrology__body-item-banner">
        <img
          class="nevrology__body-item-banner-img"
          :src="require('@/assets/img/nevro/1.jpg')"
        />
        <div class="nevrology__body-item-banner-content">
          <div class="nevrology__body-item-banner-title">
            ВсмATTRиваемся <br />в полинейропатию
          </div>
          <a
            v-if="user && Object.keys(user).length"
            href="https://az-most.ru/education/vsmattrivaemsya-v-polinejropatiyu"
            class="button button_pink d-inline-flex px-8"
          >
            Узнать про амилоидоз
            <svg
              class="ml-2"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16 8L9 16L6.5 16L12.5 9L-3.0598e-07 9L-3.93402e-07 7L12.5 7L6.5 -2.84124e-07L9 -3.93402e-07L16 8Z"
                fill="currentColor"
              ></path>
            </svg>
          </a>
          <a
            v-else
            href="https://sso.az-most.ru/signup?source=https%3A%2F%2Faz-most.ru%2Feducation%2Fvsmattrivaemsya-v-polinejropatiyu&next_url=https%3A%2F%2Faz-most.ru%2Faz-sso&back_url=https%3A%2F%2Faz-most.ru%2Feducation%2Fvsmattrivaemsya-v-polinejropatiyu"
            class="button button_pink d-inline-flex px-8"
          >
            Узнать про амилоидоз
            <svg
              class="ml-2"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16 8L9 16L6.5 16L12.5 9L-3.0598e-07 9L-3.93402e-07 7L12.5 7L6.5 -2.84124e-07L9 -3.93402e-07L16 8Z"
                fill="currentColor"
              ></path>
            </svg>
          </a>
        </div>
      </div>
      <div class="banners__text">
        Наследственный транстиретиновый амилоидоз (ATTR-амилоидоз) относят к
        орфанной патологии, которая проявляется в зрелом возрасте. Его
        диагностика сложна из-за неспецифичных симптомов, «замаскированных» под
        другие заболевания, такие как ХВДП (хроническая воспалительная
        демиелинизирующая полинейропатия), поясничная и крестцовая
        радикулопатия, боковой амиотрофический склероз и др<sup>1</sup>.
        <br />
        <br />
        ATTR-амилоидоз неуклонно прогрессирует и без адекватной терапии может
        закончиться летальным исходом через 5–15 лет у пациентов с
        полинейропатией (ПН) и через 2–6 лет у пациентов с сопутствующей или
        преобладающей кардиомиопатией (КМП)<sup>2,3</sup>. Поэтому пациенты с
        ATTR-амилоидозом нуждаются в безотлагательном лечении.
        <br />
        <br />
        <a
          v-if="user && Object.keys(user).length"
          href="https://az-most.ru/education/vsmattrivaemsya-v-polinejropatiyu"
          class="button button_pink d-inline-flex px-8"
        >
          Больше информации про амилоидоз
        </a>
        <a
          v-else
          href="https://sso.az-most.ru/signup?source=https%3A%2F%2Faz-most.ru%2Feducation%2Fvsmattrivaemsya-v-polinejropatiyu&next_url=https%3A%2F%2Faz-most.ru%2Faz-sso&back_url=https%3A%2F%2Faz-most.ru%2Feducation%2Fvsmattrivaemsya-v-polinejropatiyu"
          class="button button_pink d-inline-flex px-8"
        >
          Больше информации про амилоидоз
        </a>
        <br />
        <br />
        Чтобы узнать больше о спецпроекте, зарегистрируйтесь
        <br />
        <br />
        <b>Список литературы:</b><br />
        1. Никитин С.С., Бардаков С.Н., Супонева Н.А. и др. Фенотипическая
        гетерогенность и особенности диагностики транстиретинового амилоидоза с
        полинейропатией. Нервно-мышечные болезни 2021;11(3):12–36. DOI:
        10.17650/2222‑8721‑2021‑11‑3‑12‑36.<br />
        2. Hawkins P.N. et al. Ann Med. 2015;47(8):625-638.<br />
        3. Witteles R.M. et al. JACC Heart Fail. 2019;7(8):709-716.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BannersPage",
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style lang="scss" scoped>
.banners {
  padding-top: 48px;

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;

    & b {
      font-weight: 700;
    }

    sub,
    sup {
      vertical-align: super;
      font-family: "Roboto", sans-serif;
      font: inherit;
      font-size: small;
      color: inherit;
      white-space: nowrap;
    }
  }
}
.nevrology__body-item {
  &-banner {
    width: 100%;
    height: auto;
    max-width: 700px;
    border-radius: 32px;
    overflow: hidden;
    position: relative;
    margin-bottom: 24px;

    @media screen and (max-width: 1220px) {
      width: auto;
      min-height: 116px;
      border-radius: 20px;
    }

    &-img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
    }

    &-content {
      padding: 32px 0 32px 32px;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      & > .button {
        margin-top: auto;
      }
    }

    &-title {
      margin-bottom: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 32px;
      color: #ffffff;

      @media screen and (max-width: 1220px) {
        font-family: "Roboto Slab", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 21px;
        line-height: 26px;
        color: #ffffff;

        & > br {
          display: none;
        }
      }
    }
  }
}
</style>